import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const SignUpPage = () => (
  <div>
    <h1>SignUp</h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  newsletterConsent: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { firstName, lastName, email, passwordOne, newsletterConsent } = this.state;
    const roles = {};

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            firstName,
            lastName,
            email,
            newsletterConsent
          }
        );
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      passwordOne,
      passwordTwo,
      newsletterConsent,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      firstName === '' ||
      lastName === '';

    return (
      <form onSubmit={this.onSubmit}>
        {error? <div className="modal-status">{error.message}</div> : null}
        <h3 className="caps-text-13 grey">First Name</h3>
        <input
          className="login-input"
          name="firstName"
          value={firstName}
          onChange={this.onChange}
          type="text"
          placeholder="First Name"
        />
        <h3 className="caps-text-13 grey">Last Name</h3>
        <input
          className="login-input"
          name="lastName"
          value={lastName}
          onChange={this.onChange}
          type="text"
          placeholder="Last Name"
        />
        <h3 className="caps-text-13 grey">Email</h3>
        <input
          className="login-input"
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email"
        />
        <h3 className="caps-text-13 grey">Password</h3>
        <input
          className="login-input"
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <h3 className="caps-text-13 grey">Confirm Password</h3>
        <input
          className="login-input"
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
        />
        <label className="login-label">
          <input
            name="newsletterConsent"
            type="checkbox"
            checked={newsletterConsent}
            onChange={this.onChangeCheckbox}
          />
          Subscribe to the newsletter
        </label>
        <button className="login-button" disabled={isInvalid} type="submit">
          <img alt="Register" className="login-button-img" src="/ARCH_type_register.svg"/>
        </button>

        <div className="page-button-parent">
          <button className="page-button" type="button" onClick={() => this.props.setPage(0)}>> I already have an account, return to sign in.</button>
        </div>
      </form>
    );
  }
}

class SignUpLink extends Component {
  render() {
    return(
      <button className="page-button" type="button" onClick={() => this.props.setPage()}>> I need an account.</button>
    )
  }
}

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
