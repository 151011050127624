import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import LightboxList from './LightboxList';
import CreateLightbox from './CreateLightbox';

class Lightboxes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      lightboxes: [],
      authRef: this.props.firebase.user(this.props.authUser.uid)
    };
  }

  componentDidMount() {
    this.onListenForLightboxes();
  }

  onListenForLightboxes = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .lightboxes()
      .where("creatorID", "==", this.props.authUser.uid)
      .orderBy('modifiedAt', 'desc')
      .onSnapshot(async snapshot => {
        if (snapshot.size) {
          let lightboxes = [];
          snapshot.forEach(doc => 
            lightboxes.push({ ...doc.data(), uid: doc.id })
          ,);

          for (let l = 0; l < lightboxes.length; l++) {
            for (let i = 0; i < Math.min(lightboxes[l].images.length, 1); i++) { // just run to lightboxes[l].images.length for all images
              let image = await lightboxes[l].images[i].get()
              lightboxes[l].images[i].fileName = image.data().fileName
            }
          }

          this.setState({
            lightboxes: lightboxes,
            loading: false,
          });
        } else {
          this.setState({ lightboxes: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onEditLightbox = (lightbox, text) => {
    const { uid, ...lightboxSnapshot } = lightbox;

    this.props.firebase.lightbox(lightbox.uid).update({
      ...lightboxSnapshot,
      text,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onCreateLightbox = name => {
    this.props.firebase.lightboxes().add({
      createdAt: this.props.firebase.NOW(),
      modifiedAt: this.props.firebase.NOW(),
      creator: this.state.authRef,
      creatorID: this.props.authUser.uid,
      images: [],
      name
    })
    .then(function (docRef) {
      console.log("Lightbox created with ID: ", docRef.id);
    })
    .catch(function (error) {
      console.error("Error creating lightbox: ", error);
    });
  }

  render() {
    const { authUser } = this.props;
    const { text, lightboxes, loading } = this.state;

    return (
      <div>

        <div className="header-row">
          <div className="half-col caps-text-13 red">My Lightboxes</div>
          <div className="half-col right">
            {!loading && <CreateLightbox onCreateLightbox={this.onCreateLightbox}/>}
          </div>
        </div>

        {loading && <div>Loading...</div>}

        {lightboxes && (
          <LightboxList
            authUser={authUser}
            lightboxes={lightboxes}
            onEditLightbox={this.onEditLightbox}
            onRemoveLightbox={this.onRemoveLightbox}
          />
        )}

        {!lightboxes && <div>You haven't created any lightboxes yet, get started now!</div>}

        {/* <form
          onSubmit={event =>
            this.onCreateLightbox(event, authUser)
          }
        >
          <input
            type="text"
            value={text}
            onChange={this.onChangeText}
          />
          <button type="submit">Send</button>
        </form> */}
      </div>
    );
  }
}

export default withFirebase(Lightboxes);
