import React, { Component } from 'react';

import { AuthUserContext } from '../Session'
import Upload from './Upload';

class UploadPage extends Component {
    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <Upload authUser={authUser} location={this.props.location} />
                )}
            </AuthUserContext.Consumer>
        )
    }
};

export default UploadPage;
