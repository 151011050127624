import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import SignOutButton from '../SignOut';
import Header from '../Header';
import SignInModal from '../SignIn';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <div>
    <div className="above-sticky-nav"></div>
    <div className="sticky-nav">
      <div className="sticky-nav-inner caps-text-13 red header-bar-text">
        <div className="left">
          <Link to={ROUTES.HOME}>Archive</Link>
          <span className="slash-spacer">//</span>
          <NavLink to={ROUTES.LIGHTBOXES} className="sticky-deselected" activeClassName="sticky-selected">
          LIGHTBOXES
          </NavLink>
          {authUser && authUser.admin?
            <fragment>
              <span className="slash-spacer">//</span>
              <NavLink to={ROUTES.UPLOAD} className="sticky-deselected" activeClassName="sticky-selected">
              UPLOAD
              </NavLink>
            </fragment>
          :null}
        </div>
        <div className="right">
          {authUser.firstName?
            <SignOutButton name={authUser.firstName} />
          :
            <SignInModal />
          }
        </div>
      </div>
    </div>
    <div className="sticky-nav-spacer"></div>
    <div>
      <Header />
    </div>
  </div>
);

const NavigationNonAuth = () => (
  <div>
    <div className="above-sticky-nav"></div>
    <div className="sticky-nav">
      <div className="sticky-nav-inner caps-text-13 red header-bar-text">
        <div className="left">
          <Link to={ROUTES.HOME}>Archive</Link>
          <span className="slash-spacer mobile">//</span>
          {/* <span to={ROUTES.LIGHTBOXES} className="sticky-deselected sticky-disabled" activeClassName="sticky-selected">
          LIGHTBOXES
          </span> */}
        </div>
        <div className="right">
          <SignInModal />
        </div>
      </div>
    </div>
    <div className="sticky-nav-spacer"></div>
    <div>
      <Header />
    </div>
  </div>
);

export default Navigation;
