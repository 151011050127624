import React, { Component } from 'react';

import history from '../../history';
import { withFirebase } from '../Firebase';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      keywordModalOpen: false,
      keywords: {}
    };
  }

  componentDidMount() {
    this.getKeywords()
  }

  getKeywords = () => {
    this.props.firebase.keywords().get()
      .then(snapshot => {
        this.setState({
          keywordStructure: snapshot.data(),
        })
      });
  };

  onChangeSearch(e) {
    this.setState({search: e.target.value})
  }

  onClearSearch(e) {
    this.setState({search: ''})
  }

  onSearch(e) {
    e.preventDefault();
    history.push('/search?q=' + this.state.search)
  }

  toggleModal() {
    this.setState({keywordModalOpen: !this.state.keywordModalOpen})
  }

  toggleKeyword(word) {
    const { keywords, search } = this.state
    let newState = true
    let newSearch = search
    if (word in keywords) {
      newState = !keywords[word]
    }

    if (newState === true) {
      let lead = ''
      if (newSearch.trim().length > 0) {
        lead = ', '
      }
      newSearch = (newSearch.trim() + lead + word).trim()
    } else {
      newSearch = newSearch.replace(', ' + word, '').trim()
      newSearch = newSearch.replace(word, '').trim()
      if (newSearch.length > 1 && newSearch.substr(0,2) === ', ' ) {
        newSearch = newSearch.substr(2).trim()
      }
    }

    this.setState({keywords: {...keywords, [word]: newState}, search: newSearch})
  }

  render() {
    const { search, keywordModalOpen, keywords, keywordStructure } = this.state

    if (window.location.pathname === '/') {
      return (
      <form onSubmit={(e) => this.onSearch(e)}>
        <div className="big-copyright">
          Thousands of images in the Rodney Smith Archive are available to license for commercial purposes. All images are <span className="red">copyrighted</span> and permissions may be granted by contacting the studio: Forward your custom lightbox to us at studio@rodneysmith.com or call 845.359.3814.
        </div>
        <div className="search-archive-button"><img className="search-archive-img" src="/ARCH_type_search_the_archive2.svg"/></div>
        <div className="search-archive-inputs">
          <input className={keywordModalOpen? "search-archive-input modal-open" : "search-archive-input"} placeholder="enter keywords" value={search} onChange={(e) => this.onChangeSearch(e)}/>
          {!keywordModalOpen? <button type="button" onClick={() => this.toggleModal()} className="search-archive-keyword-button">select keywords</button> :null}
          <button type="submit" className={keywordModalOpen? "search-archive-primary-button modal-open" : "search-archive-primary-button"}><span className="search-archive-secondary-label">SEARCH </span><img className="search-archive-secondary-button-img primary" src="/ARCH_icon_go_arrow.svg" /></button>
          {keywordModalOpen? <div className="keyword-modal">
            <a onClick={() => this.toggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
            {keywordStructure.order.map((title, i) => 
              <div key={title} className="keyword-col">
                <div className="keyword-header">{title}</div>
                {keywordStructure[title].map(keyword =>
                  <button type="button" key={keyword} onClick={() => this.toggleKeyword(keyword)} className={keywords[keyword]? "keyword-selector selected" : "keyword-selector"}>{keyword}</button>
                )}
                {i === 3?
                  <button type="submit" className="search-archive-small-button"><img className="search-archive-small-button-img" src="/ARCH_icon_go_arrow.svg" /></button>
                : null }
              </div>
            )}
          </div>:null}
        </div>
      </form>
      )
    }

    return (
      <form className="search-archive-parent-secondary" onSubmit={(e) => this.onSearch(e)}>
          <input className="search-archive-input full" placeholder="enter keywords" value={search} onChange={(e) => this.onChangeSearch(e)}/>
          <button className="search-archive-secondary-button"><span className="search-archive-secondary-label">SEARCH </span><img className="search-archive-secondary-button-img" src="/ARCH_icon_go_arrow.svg" /></button>
      </form>
    );
  }
}

export default withFirebase(Header);
