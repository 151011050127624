import algoliasearch from 'algoliasearch/lite';

class Algolia {
  constructor() {
    this.client = algoliasearch(process.env.REACT_APP_ALGOLIA_API_CLIENT, process.env.REACT_APP_ALGOLIA_API_SEARCH_KEY);
    this.images = this.client.initIndex('images');
  }

  // *** Search API ***

  search = (query, page, hits) => this.images.search(query, {
    page: page || 0,
    hitsPerPage: hits || 48,
  });

}

export default Algolia;