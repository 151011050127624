import React from 'react';

import { AuthUserContext } from '../Session'
import Gallery from '../Gallery';

const HomePage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <Gallery authUser={authUser} />
      </div>
    )}
  </AuthUserContext.Consumer>
);

export default HomePage;
