import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';
import UTIF from 'utif'

class DisplayFiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalState: -1,
    };

    this.tiffRef = React.createRef();
    this.resizeRef1 = React.createRef();
    this.resizeRef2 = React.createRef();
  }

  openModal(i) {
    if (-1 < i && i < this.props.displayFiles.length) {
      this.setState({modalState: i})
    }
  }

  closeModal() {
    this.setState({modalState: -1})
  }

  async loadTiff(file) {
    const response = await fetch(file.url);
    const buffer = await response.arrayBuffer();
    let ifds = UTIF.decode(buffer)
    UTIF.decodeImage(buffer, ifds[0])
    let rgba  = new Uint8ClampedArray(UTIF.toRGBA8(ifds[0]))
    let data = new ImageData(rgba, ifds[0].width, ifds[0].height)
    const mainCanvas = this.tiffRef.current
    const resizeCanvas1 = this.resizeRef1.current
    const resizeCanvas2 = this.resizeRef2.current
    const ctx1 = resizeCanvas1.getContext('2d')
    const ctx2 = resizeCanvas2.getContext('2d')
    ctx1.putImageData(data, 0, 0)
    const scaleFactor = 500 / ifds[0].width
    ctx2.scale(scaleFactor, scaleFactor)
    ctx2.drawImage(resizeCanvas1, 0, 0)
    const newHeight = ifds[0].height * scaleFactor
    mainCanvas.height = newHeight
    const smallImageData = ctx2.getImageData(0, 0, mainCanvas.width, mainCanvas.height)
    mainCanvas.getContext('2d').putImageData(smallImageData, 0, 0)
  }

  render() {
    const { displayFiles, downloadFile } = this.props;
    const { modalState } = this.state;

    return (
        <div className="files-parent">
            {displayFiles.map((displayFile, i) => (
                <div className="single-file-parent" key={i}>
                    <div className="file-body">
                        <img className="file-icon" src="/ARCH_icon_file.svg"/>
                        {displayFile.type ? <div className="file-type">.{displayFile.type.toUpperCase()}</div> : null}
                        <div className="file-name">{displayFile.name}</div>
                    </div>
                    <div className="file-buttons">
                        <button className="header-button" onClick={() => this.openModal(i)}>
                            <img className="header-button-img" src="/ARCH_icon_preview.svg"/>
                        </button>
                        <button className="header-button" onClick={() => downloadFile(i)}>
                            <img className="header-button-img" src="/ARCH_icon_download.svg"/>
                        </button>
                    </div>
                </div>
            ))}
            {modalState >= 0?
                <div className="modal-window">
                    <div>
                        <div className="modal-fixed-inner">
                            <a onClick={() => this.closeModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
                            {displayFiles[modalState].type == "tiff"
                              ? <div>
                                  <canvas className="tiff-canvas" width={500} ref={this.tiffRef} onLoad={this.loadTiff(displayFiles[modalState])}></canvas>
                                  <canvas className="resize-canvas" width={10000} height={10000} ref={this.resizeRef1}></canvas>
                                  <canvas className="resize-canvas" width={10000} height={10000} ref={this.resizeRef2}></canvas>
                                </div>
                              : <FileViewer
                                  fileType={displayFiles[modalState].type}
                                  filePath={displayFiles[modalState].url}
                                  errorComponent={<div>error</div>}
                                  onError={this.onError}/>
                            }
                        </div>
                    </div>
                </div>
            :null}
        </div>
    );
  }
}

export default DisplayFiles;
