import React, { Component } from 'react';

class CreateLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      name: ''
    };
  }

  onToggleModal = () => {
    this.setState(state => ({
      modalOpen: !state.modalOpen,
    }));
  };

  onChangeName = event => {
    this.setState({ name: event.target.value });
  };

  render() {
    const { onCreateLightbox } = this.props;
    const { modalOpen, name } = this.state;

    return (
      <span>
        <button className="header-button" onClick={() => this.onToggleModal()}>
          <img className="header-button-img" src="/ARCH_icon_new_lightbox.svg"/>
          <div className="header-button-text">new lightbox</div>
        </button>

        {modalOpen?
          <div className="modal-window">
            <div>
              <a onClick={() => this.onToggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
              <div className="caps-text-13 red">New Lightbox</div>
              <div className="create-lightbox-flex">
                <input placeholder="Name new lightbox" className="modal-input" onChange={(e) => this.onChangeName(e)}/>
                <button className="create-lightbox-button" onClick={() => {this.setState({modalOpen: false}); onCreateLightbox(name)}}><img className="create-lightbox-go" src="/ARCH_icon_go_arrow.svg" /></button>
              </div>
            </div>
          </div>
        :null}
      </span>
    );
  }
}

export default CreateLightbox;
