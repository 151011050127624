import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      let lsEnabled = this.lsTest();
      let authUser = undefined;
      if (lsEnabled) {
          authUser = JSON.parse(localStorage.getItem('authUser'));
      }
      this.state = {
        lsEnabled,
        authUser
      };
    }

    lsTest() {
      var test = 'test';
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          if (this.state.lsEnabled) {
            localStorage.setItem('authUser', JSON.stringify(authUser));
            this.setState({ authUser });
          }
        },
        () => {
          if (this.state.lsEnabled) {
            localStorage.removeItem('authUser');
            this.setState({ authUser: null });
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
