import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink, SignUpForm } from '../SignUp';
import { PasswordForgetLink, PasswordForgetForm } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class SignInModal extends Component {
  constructor(props) {
    super(props);

    this.state = { modalOpen: false, page: 0 };
    // pages: 0 - sign in, 1 - sign up, 2 - forgot pass
  }

  onToggleModal = () => {
    this.setState(state => ({
      modalOpen: !state.modalOpen,
    }));
  };

  setPage = (pageNum) => {
    if (-1 < pageNum < 3) {
      this.setState({ page: pageNum });
    }
  };

  render() {
    const { modalOpen } = this.state;

    return (
      <div>
        <button className="signin-button caps-text-13" onClick={() => this.onToggleModal()}>Sign In<span className="caps-text-13 grey">&nbsp;&nbsp;••&nbsp;&nbsp;</span>Register</button>
        {modalOpen?
          <div className="modal-window">
            <div>
              <a onClick={() => this.onToggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
              {this.state.page === 0?
                <SignInForm setPage={(n) => this.setPage(n)} />
              : this.state.page === 1?
                <SignUpForm setPage={(n) => this.setPage(n)} />
              : <PasswordForgetForm setPage={(n) => this.setPage(n)} />
              }
              {/* <SignInGoogle />
              <SignInFacebook />
              <SignInTwitter /> */}
            </div>
          </div>
        :null}
      </div>
    );
  }
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        if (error.code === 'auth/wrong-password') {
          error.resetReq = true
        }
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        {error && error.resetReq? <div className="modal-status">Welcome to the new Rodney Smith Archive! If you haven't logged in yet, your lightboxes are still here on the new site. However, we'll need to verify your identity through a password reset. Just click "I forgot my password" below.</div> :null}
        {error? <div className="modal-status">{error.message}</div> : null}
        <h3 className="caps-text-13 grey">Email</h3>
        <input
        className="login-input"
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email"
        />
        <h3 className="caps-text-13 grey">Password</h3>
        <input
          className="login-input"
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />

        <div className="page-button-parent">
          <PasswordForgetLink setPage={() => this.props.setPage(2)} />
          <SignUpLink setPage={() => this.props.setPage(1)} />
        </div>

        <button className="login-button" disabled={isInvalid} type="submit">
          <img alt="Sign In" className="login-button-img" src="/ARCH_type_signin.svg"/>
        </button>
      </form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            roles: {},
          },
          { merge: true },
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            roles: {},
          },
          { merge: true },
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            roles: {},
          },
          { merge: true },
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

export default SignInModal;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
