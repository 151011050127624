import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';

import history from '../../history'

import Navigation from '../Navigation';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import LightboxesPage from '../Lightboxes';
import LightboxPage from '../Lightbox';
import SearchPage from '../Search';
import UploadPage from '../Upload';
import DownloadPage from '../Download';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: null
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIframeLoad);
  }

  handleIframeLoad = (e) => {
    if (e.origin !== ROUTES.HOST) {
      return;
    }
    
    this.setState({...e.data})
    let search = e.data.search

    var obj = {};
    search.substr(1).replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
      obj[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    if (obj.l) {
      history.push('/lightbox' + search)
    } else if (obj.q) {
      history.push('/search' + search)
    } else if (obj.d) {
      history.push('/download' + search)
    }

    window.parent.postMessage({
      received: true
    }, ROUTES.HOST)
  }

  render() {
    return(
      <Router history={history}>
        <div className="container">
          <Navigation />

          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
          <Route path={ROUTES.LIGHTBOXES} component={LightboxesPage} />
          <Route path={ROUTES.LIGHTBOX} component={LightboxPage} />
          <Route path={ROUTES.SEARCH} component={SearchPage} />
          <Route path={ROUTES.UPLOAD} component={UploadPage} />
          <Route path={ROUTES.DOWNLOAD} component={DownloadPage} />
        </div>
      </Router>
    )
  }
}

export default withAuthentication(App);
