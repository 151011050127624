import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { withFirebase } from '../Firebase';
import { withAlgolia } from '../Algolia';
import GalleryItem from '../GalleryItem';

class Search extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      query: null,
      results: [],
      loading: false,
      loadingLightboxes: false,
      lightboxes: [],
      page: 0,
      totalPages: 0,
      totalHits: 0
    };
  }

  extractQuery(search) {
    var params = {};
    search.substr(1).replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    });
    return(params.q);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.onLoad(this.extractQuery(nextProps.location.search))
    }
  }

  componentDidMount() {
    this.onLoad(this.extractQuery(this.props.location.search));
    this.onListenForLightboxes();
  }

  onLoad = (query) => {
    this.setState({
      loading: true,
      page: 0
    });
    if (query) {
      this.onSearch(query);
    }
  }

  onSearch = (query) => {
    this.setState({query})
    this.props.algolia.search(query, 0)
      .then(data => {
        this.processImages(data, true)
      })
  }

  onNextPage = () => {
    this.props.algolia.search(this.state.query, this.state.page + 1)
      .then(data => {
        this.processImages(data)
        this.setState({page: this.state.page + 1})
      })
  }

  processImages(data, replace) {
    let results = data.hits
    for (let i=0; i < results.length; i++) {
      results[i].uid = results[i].archiveNumber
    }
    let newResults
    if (replace) {
      newResults = results
    } else {
      newResults = this.state.results.concat(results)
    }
    this.setState({results: newResults, loading: false, totalPages: data.nbPages, totalHits: data.nbHits});
  }

  onListenForLightboxes = () => {
    if (this.props.authUser && this.props.authUser.uid) {
      this.setState({ loadingLightboxes: true });

      this.unsubscribeLightboxes = this.props.firebase
        .lightboxes()
        .where("creatorID", "==", this.props.authUser.uid)
        .orderBy('modifiedAt', 'desc')
        .onSnapshot(async snapshot => {
          if (snapshot.size) {
            let lightboxes = {};
            snapshot.forEach(doc => 
              lightboxes[doc.id] = {...doc.data(), uid: doc.id }
            ,);

            this.setState({ lightboxes: lightboxes, loadingLightboxes: false });
          } else {
            this.setState({ lightboxes: [], loadingLightboxes: false });
          }
        });
    }
  };

  componentWillUnmount() {
    if (this.unsubscribeLightboxes) {
      this.unsubscribeLightboxes();
    }
  }

  render() {
    const { query, results, loading, loadingLightboxes, lightboxes, totalPages, page, totalHits } = this.state;

    return (
      <div>
          <div className="caps-text-13 grey search-header">{totalHits} results for {query}</div>
          <div>
            <InfiniteScroll
              className="gallery-full"
              dataLength={results? results.length : 0}
              next={this.onNextPage}
              hasMore={totalPages > page + 1}
              loader={<div>Loading...</div>}
            >
              {!loading && !loadingLightboxes && results ?
                results.map((image, index) => (
                  <GalleryItem authUser={this.props.authUser} key={index} image={image} lightboxes={lightboxes}/>
                ))
              : null}
            </InfiniteScroll>
          </div>
      </div>
    );
  }
}

export default withFirebase(withAlgolia(Search));
