import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import GalleryList from './GalleryList';

class Gallery extends Component {
  constructor(props) {
    super(props);

    let authRef = null
    if (this.props.authUser && this.props.authUser.uid) {
      authRef = this.props.firebase.user(this.props.authUser.uid)
    }

    this.state = {
      loadingImages: false,
      loadingLightboxes: false,
      loadingLayout: false,
      images: [],
      lightboxes: [],
      limit: 5,
      increment: 5,
      authRef
    };
  }

  componentDidMount() {
    this.onListenForImages();
    this.onListenForLightboxes();
    this.getLayout();
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.authUser || !prevProps.authUser.uid)) {
      this.onListenForLightboxes();
    }
  }

  onListenForImages = () => {
    this.setState({ loadingImages: true });

    this.unsubscribe = this.props.firebase
      .images()
      .where('landing', '==', true)
      // .limit(this.state.limit)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let images = [];
          snapshot.forEach(doc =>
            images.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            images: images,
            loadingImages: false,
          });
        } else {
          this.setState({ images: null, loadingImages: false });
        }
      });
  };

  onListenForLightboxes = () => {
    if (this.props.authUser && this.props.authUser.uid) {
      this.setState({ loadingLightboxes: true });

      this.unsubscribeLightboxes = this.props.firebase
        .lightboxes()
        .where("creatorID", "==", this.props.authUser.uid)
        .orderBy('modifiedAt', 'desc')
        .onSnapshot(async snapshot => {
          if (snapshot.size) {
            let lightboxes = {};
            snapshot.forEach(doc => 
              lightboxes[doc.id] = {...doc.data(), uid: doc.id }
            ,);

            this.setState({ lightboxes: lightboxes, loadingLightboxes: false });
          } else {
            this.setState({ lightboxes: [], loadingLightboxes: false });
          }
        });
    }
  };

  getLayout = () => {
    this.setState({ loadingLayout: true });

    this.props.firebase.layout().get()
      .then(snapshot => {
        this.setState({layout: snapshot.data(), loadingLayout: false})
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
    if (this.unsubscribeLightboxes) {
      this.unsubscribeLightboxes();
    }
  }

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + state.increment }),
      this.onListenForImages,
    );
  };

  render() {
    const {
      images,
      lightboxes,
      loadingImages,
      loadingLightboxes,
      loadingLayout,
      layout
    } = this.state;

    return (
      <div>
        {(loadingImages || loadingLightboxes) && <div>Loading...</div>}

        {images && lightboxes && !loadingImages && !loadingLightboxes && !loadingLayout && (
          <GalleryList
            authUser={this.props.authUser}
            images={images}
            lightboxes={lightboxes}
            layout={layout}
          />
        )}

        {!images && <div>There are no images ...</div>}

        {/* {!(loadingImages || loadingLightboxes) && images && (
          <button type="button" onClick={this.onNextPage}>
            More
          </button>
        )} */}
      </div>
    );
  }
}

export default withFirebase(Gallery);
