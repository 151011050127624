import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase, name }) => (
  <span className="caps-text-13 red">
    <span className="mobile-hide">
      <img src="/ARCH_icon_avatar.svg" className="header-avatar"/>{name}
    </span>
    <span className="caps-text-13 grey">&nbsp;&nbsp;••&nbsp;&nbsp;</span>
    <button type="button" className="signout-button caps-text-13 red" onClick={firebase.doSignOut}>Sign Out</button>
  </span>
);

export default withFirebase(SignOutButton);
