import React, { Component } from 'react';

import { AuthUserContext } from '../Session'
import Download from './Download';

class DownloadPage extends Component {
    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <Download authUser={authUser} location={this.props.location} />
                )}
            </AuthUserContext.Consumer>
        )
    }
};

export default DownloadPage;
