import React, { Component } from 'react';

import history from '../history'
import { withFirebase } from './Firebase';

class DeleteLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  onToggleModal = () => {
    this.setState(state => ({
      modalOpen: !state.modalOpen,
    }));
  };

  onDeleteLightbox = lightbox => {
    this.props.firebase.lightbox(lightbox.uid).delete();
    history.push('/lightboxes');
  };

  render() {
    const { lightbox, invert } = this.props;
    const { modalOpen } = this.state;

    return (
      <span>
        <button className={invert? "header-button" : "header-button top"} onClick={() => this.onToggleModal()}>
          <img className="header-button-img" style={{height: '18px'}} src={invert? "/ARCH_icon_delete_white.svg" : "/ARCH_icon_delete.svg"}/>
          <div className={invert? "header-button-text bottom-bar" : "header-button-text"}>delete</div>
        </button>

        {modalOpen?
          <div className="modal-window">
            <div>
              <a onClick={() => this.onToggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
              <div className="caps-text-13 red">DELETE <span className="mobile-hide">{lightbox.name}?</span><span className="mobile-show">LIGHTBOX</span></div>
              <div>
                <button className="modal-button dark-gray" onClick={() => this.onDeleteLightbox(lightbox)}>Yes, delete</button>
                <button className="modal-button red" onClick={() => this.setState({modalOpen: false})}>No, cancel</button>
              </div>
            </div>
          </div>
        :null}
      </span>
    );
  }
}

export default withFirebase(DeleteLightbox);
