import React, { Component } from 'react';

import * as ROUTES from '../constants/routes';

class ShareLightbox extends Component {
  constructor(props) {
    super(props);
    
    let link = ''
    if (this.props.lightbox) {
      link = window.location.ancestorOrigins[0] + ROUTES.PAGE + '/?l=' + this.props.lightbox.uid
    } else {
      link = window.location.ancestorOrigins[0] + ROUTES.PAGE + '/' + window.location.search
    }

    this.state = {
      modalOpen: false,
      link
    };
  }

  onToggleModal = () => {
    this.setState(state => ({
      modalOpen: !state.modalOpen,
      status: null
    }));
  };

  onCopyShareLink = () => {
    var element = document.getElementById("copy-target");
    var range, selection;

    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();        
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    
    try {
      document.execCommand('copy');
      this.setState({status: "Share link copied."})
    }
    catch (err) {
      this.setState({status: 'Could not copy share link. You can copy it manually here: ' + this.state.link});
    }
  };

  render() {
    const { invert } = this.props;
    const { modalOpen, link } = this.state;

    return (
      <span>
        <button className={invert? "header-button" : "header-button top"} onClick={() => this.onToggleModal()}>
          <img className="header-button-img" style={{height: '28px'}} src={invert? "/ARCH_icon_share_white.svg" : "/ARCH_icon_share.svg"}/>
          <div className={invert? "header-button-text bottom-bar" : "header-button-text"}>share</div>
        </button>

        {modalOpen?
          <div className="modal-window">
            <div>
              <a onClick={() => this.onToggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
              <div className="caps-text-13 red">Share Lightbox</div>
              {this.state.status? <div className="modal-status">{this.state.status}</div> :null}
              <div>
                <div id="copy-target">{link}</div>
                <button className="modal-button dark-gray" onClick={() => this.onCopyShareLink()}>COPY A LINK</button>
                <a className="modal-button modal-link dark-gray" href={"mailto:?subject=Check%20out%20my%20Rodney%20Smith%20lightbox&body=View%20my%20custom%20lightbox%20at%20" + encodeURIComponent(this.state.link)}>EMAIL A LINK</a>
              </div>
            </div>
          </div>
        :null}
      </span>
    );
  }
}

export default ShareLightbox;
