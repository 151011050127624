import React, { Component } from 'react';

import GalleryItem from '../GalleryItem';

class GalleryList extends Component {

  isBig(archive) {
    if (this.props.layout) {
      return this.props.layout.big.includes(archive)
    }
    return false
  }

  lookupCol(col, lookup) {
    let res = []
    for (let archive in col) {
      res.push(lookup[col[archive]])
    }
    return res
  }

  render() {
    const { authUser, images, lightboxes, layout } = this.props;

    var imagesThirds = [[],[],[]]

    if (layout) {
      let lookup = {}
      for (let img in images) {
        lookup[images[img].archiveNumber] = images[img]
      }
      imagesThirds[0] = this.lookupCol(layout.col1, lookup)
      imagesThirds[1] = this.lookupCol(layout.col2, lookup)
      imagesThirds[2] = this.lookupCol(layout.col3, lookup)
    } else {
      var third = Math.ceil(images.length / 3);
      imagesThirds = [images.slice(0, third), images.slice(third, 2 * third), images.slice(2 * third, images.length)]
    }

    return(
      imagesThirds.map(imageThird => (
        <div className="gallery-parent">
          <div className="gallery-spacer"></div>
          {imageThird.map(image => (
            <GalleryItem
              authUser={authUser}
              key={image.uid}
              image={image}
              lightboxes={lightboxes}
              big={this.isBig(image.archiveNumber)}
            />
          ))}
        </div>
      ))
    )
  }
}

export default GalleryList;
