import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

const PasswordForgetPage = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
  sent: false
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE, sent: true });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <div className="caps-text-13 red">Reset Password</div>
        {this.state.sent? <div className="modal-status">Password reset sent. Please check your email to continue.</div> : this.state.error? <div className="modal-status">{error.message}</div> : null}
        <div className="create-lightbox-flex">
          <input
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Email address"
            className="modal-input"
          />
          <button className="create-lightbox-button" disabled={isInvalid} type="submit"><img alt="Reset Password" className="create-lightbox-go" src="/ARCH_icon_go_arrow.svg" /></button>
        </div>

        <div className="page-button-parent no-bottom">
          <button className="page-button" type="button" onClick={() => this.props.setPage(0)}>> I know my password, return to sign in.</button>
        </div>
      </form>
    );
  }
}

class PasswordForgetLink extends Component {
  render() {
    return(
      <button className="page-button" type="button" onClick={() => this.props.setPage()}>> I forgot my password.</button>
    )
  }
}

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
