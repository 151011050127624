import React, { Component } from 'react';
import { Page, Text, View, Document, Image, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';

import { withFirebase } from './Firebase';
import { STORAGE_URL } from '../constants/firebase';

class PrintLightbox extends Component {
  constructor(props) {
    super(props);

    this.viewerRef = React.createRef();

    this.state = {
      modalOpen: false,
      loadedResources: false,
      processedLightbox: null
    };
  }

  componentDidMount() {
    this.loadResources()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lightbox !== this.props.lightbox) {
      this.loadResources()
    }
  }

  loadResources() {
    const { lightbox } = this.props;
    
    if (lightbox && lightbox.creator) {
      lightbox.creator.get()
        .then(creator => {
          let promises = []
          for (let image in lightbox.images) {
            promises.push(this.toDataURL(STORAGE_URL(lightbox.images[image].id, "thumb_large")))
          }

          Promise.all(promises).then((dataURLs) => {
            for (let image in lightbox.images) {
              lightbox.images[image].dataURL = dataURLs[image]
            }
            let creatorData = creator.data()
            let creatorName = creatorData.firstName + ' ' + creatorData.lastName
            this.setState({
              processedLightbox: {...lightbox, creatorName},
              loadedResources: true,
            })
        })
      })
    }
  }

  toDataURL(url) {
    return fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))
  }

  onToggleModal = () => {
    this.setState(state => ({
      modalOpen: !state.modalOpen,
    }));
  };

  onPrintLightbox() {
    if (
      this.viewerRef &&
      this.viewerRef.current &&
      this.viewerRef.current.children &&
      this.viewerRef.current.children[0] &&
      this.viewerRef.current.children[0].src
    ) {
      window.open(this.viewerRef.current.children[0].src);
    }
  };

  render() {
    const { lightbox, invert } = this.props;
    const { modalOpen, loadedResources, processedLightbox } = this.state;

    return (
      <span>
        <button className={invert? "header-button" : "header-button top"} onClick={() => this.onToggleModal()}>
          <img className="header-button-img" src={invert? "/ARCH_icon_print_white.svg" : "/ARCH_icon_print.svg"}/>
          <div className={invert? "header-button-text bottom-bar" : "header-button-text"}>print</div>
        </button>

        {modalOpen?
          <div className="modal-window">
            <div>
              <a onClick={() => this.onToggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
              <div className="caps-text-13 red">PRINT LIGHTBOX</div>
              <div>
                <button className="modal-button dark-gray" onClick={() => this.onPrintLightbox(lightbox)}>PRINT</button>
                <button className="modal-button red" onClick={() => this.setState({modalOpen: false})}>CANCEL</button>
              </div>
              {loadedResources?
                <div ref={this.viewerRef} className="pdf-viewer-parent">
                  <PDFViewer>
                    <PrintedDocument lightbox={processedLightbox} />
                  </PDFViewer>
                </div>
              : null}
            </div>
          </div>
        :null}
      </span>
    );
  }
}

Font.register({ family: 'Montserrat', src: '/fonts/montserrat-v14-latin-regular.woff', fontWeight: 'normal' });
Font.register({ family: 'Montserrat', src: '/fonts/Montserrat-SemiBold.ttf', fontWeight: 'bold' });

const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  section: {
    margin: 10,
    marginLeft: 0,
    marginRight: 0,
    padding: 10,
  },
  headerImg: {
    width: 260,
    margin: 'auto',
    marginBottom: 15
  },
  header: {
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: "bold",
    color: "black",
    lineHeight: 1.4
  },
  subheader: {
    fontSize: 8,
    fontFamily: 'Montserrat',
    textTransform: "uppercase",
    fontWeight: "normal",
    color: "#878787",
    marginBottom: 25
  },
  archiveNumber: {
    fontSize: 8,
    marginTop: 5,
    marginBottom: -3,
    fontFamily: 'Montserrat',
    fontWeight: "bold"
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginLeft: 0,
    marginRight: 0,
    flexGrow: 1
  },
  gridItem: {
    display: 'inline-block',
    width: '25%',
    padding: 12,
    boxSizing: 'border-box',
  },
  archiveImg: {
    width: '100%'
  }
});

class PrintedDocument extends Component {
  render() {
    const { lightbox } = this.props

    return (
      <Document>
        <Page size="letter" style={styles.page}>
          <Image style={styles.headerImg} src="/RodneySmithPrintHeader.png"></Image>
          <View style={styles.section}>
            <Text style={styles.header}>{lightbox.name}</Text>
            <Text style={styles.subheader}>FROM {lightbox.creatorName} // {lightbox.images.length} IMAGES // CREATED {new Date(lightbox.createdAt.seconds * 1000).toLocaleDateString()}</Text>
          </View>
          <View style={styles.grid}>
            {lightbox.images.map(img =>
              !lightbox.hidden || !lightbox.hidden.includes(img.id) ?
                <View style={styles.gridItem}>
                  <Image key={img.id} style={styles.archiveImg} src={STORAGE_URL(img.id, "thumb_large")}></Image>
                  <Text style={styles.archiveNumber}>{img.id}</Text>
                </View>
              :null
            )}
          </View>
        </Page>
        {}
      </Document>
    );
  }
}

export default withFirebase(PrintLightbox);
