import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthorization } from '../Session';
import Lightboxes from './Lightboxes';

const LightboxesPage = () => (
    <AuthUserContext.Consumer>
        {authUser => (
            <div>
                <Lightboxes authUser={authUser} />
            </div>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(LightboxesPage);
