import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { STORAGE_URL } from '../../constants/firebase';

import DeleteLightbox from '../DeleteLightbox';
import ShareLightbox from '../ShareLightbox';
import DuplicateLightbox from '../DuplicateLightbox';
import PrintLightbox from '../PrintLightbox';

class LightboxItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      link: ROUTES.LIGHTBOX + "?l=" + this.props.lightbox.uid,
      editMode: false,
      editText: this.props.lightbox.text,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.lightbox.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditLightbox(this.props.lightbox, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, lightbox, onRemoveLightbox } = this.props;
    const { link, editMode, editText } = this.state;

    return (
        <div className="lightbox-item">
          {/* <div>
            {editMode ? (
              <input
                type="text"
                value={editText}
                onChange={this.onChangeEditText}
              />
            ) : (
              <div>
                <strong>{lightbox.name}</strong>
              </div>
            )}

            <DeleteLightbox lightbox={lightbox}/>
          </div> */}
          <Link to={link} className="gallery-lightbox-parent">
            <div className="gallery-gradient"></div>
            <div className="gallery-lightbox-title">{lightbox.name}</div>
            <img className="gallery-img" src={lightbox.images.length > 0? STORAGE_URL(lightbox.images[0].id, "thumb_large") : "/missing_medium.png"} />

            {/* {lightbox.images.map(img => (
              <img width="50px" key={img.fileName} src={"https://firebasestorage.googleapis.com/v0/b/rodney-smith-archive.appspot.com/o/" + img.fileName + "?alt=media"} />
            ))} */}
            
            {/* <img width="50px" src={"https://firebasestorage.googleapis.com/v0/b/rodney-smith-archive.appspot.com/o/" + lightbox.images[0].fileName + "?alt=media"} /> */}

            {/* {authUser.uid === lightbox.creator.id && (
              <span>
                {editMode ? (
                  <span>
                    <button onClick={this.onSaveEditText}>Save</button>
                    <button onClick={this.onToggleEditMode}>Reset</button>
                  </span>
                ) : (
                  <button onClick={this.onToggleEditMode}>Edit</button>
                )}

                {!editMode && (
                  <button
                    type="button"
                    onClick={() => onRemoveLightbox(lightbox.uid)}
                  >
                    Delete
                  </button>
                )}
              </span>
            )} */}
        </Link>
        <div className="gallery-lightbox-bottom">
          <DeleteLightbox invert lightbox={lightbox}/>
          <span className='mobile-hide'>
            <DuplicateLightbox invert lightbox={lightbox}/>
            <PrintLightbox invert lightbox={lightbox}/>
          </span>
          <ShareLightbox invert lightbox={lightbox}/>
        </div>
      </div>
    );
  }
}

export default LightboxItem;
