import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { STORAGE_URL } from '../../constants/firebase';

const SortableItem = SortableElement(({image, hidden}) => <div className="sortable-parent"><img className={hidden? "gallery-img sortable hidden" : "gallery-img sortable"} src={STORAGE_URL(image.archiveNumber, "thumb_large")} /></div>);

const SortableGrid = SortableContainer(({lightbox, hidden}) => {
  return (
    <div className="gallery-full">
      {lightbox.images.map((image, i) => (
        <SortableItem key={`item-${i}`} image={image} hidden={hidden && hidden.includes(image.archiveNumber)}index={i} />
      ))}
    </div>
  );
});

export default SortableGrid