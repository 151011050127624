import React, { Component } from 'react';

import { withFirebase } from './Firebase';
import { STORAGE_URL } from '../constants/firebase';

class GalleryItem extends Component {
  constructor(props) {
    super(props);

    let authRef = null
    if (this.props.authUser) {
      authRef = this.props.firebase.user(this.props.authUser.uid)
    }

    this.state = {
      modalOpen: false,
      saveOpen: false,
      name: '',
      imgSrc: STORAGE_URL(props.image.archiveNumber, "thumb_large"),
      bigImgSrc: STORAGE_URL(props.image.archiveNumber, "original"),
      lightbox: Object.keys(props.lightboxes).length > 0 ? Object.keys(props.lightboxes)[0] : null,
      authRef
    };
  }

  componentWillReceiveProps(next) {
    if (next.image !== this.props.image) {
      this.setState({
        imgSrc: STORAGE_URL(next.image.archiveNumber, "thumb_large"),
        bigImgSrc: STORAGE_URL(next.image.archiveNumber, "original")
      })
    }
  }

  clearSaveAndModal() {
    this.setState({
      modalOpen: false,
      saveOpen: false
    });
  }

  onToggleModal = () => {
    this.setState(state => ({
      modalOpen: !state.modalOpen,
    }));
  };

  onToggleSave = () => {
    this.setState(state => ({
      saveOpen: !state.saveOpen,
    }));
  };

  onChangeName = event => {
    this.setState({
      name: event.target.value
    });
  };

  onRemove = (uid, lightboxUid) => {
    this.props.firebase.lightbox(lightboxUid).get().then(snap => {
      let lightbox = snap.data()
      if (lightbox && lightbox.images) {
        this.props.firebase.lightbox(lightboxUid).update({
            images: lightbox.images.filter(image => image.id !== uid),
            modifiedAt: this.props.firebase.NOW()
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
        this.clearSaveAndModal();
      }
    })
  };

  onAdd = (uid, lightboxUid) => {
    this.props.firebase.lightbox(lightboxUid).get().then(snap => {
      let lightbox = snap.data()
      if (lightbox && lightbox.images) {
        let images = lightbox.images
        for (let i = 0; i < images.length; i++) {
          if (images[i].id === uid) {
            return;
          }
        }
        images.push(this.props.firebase.image(uid))
        this.props.firebase.lightbox(lightboxUid).update({
            images: images,
            modifiedAt: this.props.firebase.NOW()
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
        this.clearSaveAndModal();
      }
    })
  };

  onCreateLightbox(uid) {
    this.props.firebase.lightboxes().add({
      createdAt: this.props.firebase.NOW(),
      modifiedAt: this.props.firebase.NOW(),
      creator: this.state.authRef,
      creatorID: this.props.authUser.uid,
      images: [this.props.firebase.image(uid)],
      name: this.state.name
    })
    .then(function (docRef) {
      console.log("Lightbox created with ID: ", docRef.id);
    })
    .catch(function (error) {
      console.error("Error creating lightbox: ", error);
    });
    this.clearSaveAndModal();
  }

  render() {
    const { image, lightboxes, big, overlayEnabled, hidden, setHidden, uneditable, removeOnly } = this.props;
    const { modalOpen, saveOpen, imgSrc, bigImgSrc, lightbox } = this.state;

    return (
      <span key={image.fileName} className={big? "gallery-item big" : removeOnly? "gallery-item bottom-margin" : "gallery-item"}>
        <img className={hidden? "gallery-img hidden" : "gallery-img"} onClick={() => this.onToggleModal()} src={imgSrc} />
        {overlayEnabled?
        <div className="gallery-lightbox-bottom lightbox-content">
          <button className="header-button" onClick={() => this.onRemove(image.uid, lightbox)}>
            <img className="header-button-img"  style={{height: '18px'}} src={hidden? "/ARCH_icon_delete.svg" : "/ARCH_icon_delete_white.svg"}/>
            <div className={hidden? "header-button-text" : "header-button-text bottom-bar"}>delete</div>
          </button>
          {setHidden?
            hidden?
              <button className="header-button" onClick={() => setHidden(false)}>
                <img className="header-button-img" src="/ARCH_icon_show.svg"/>
                <div className="header-button-text">show</div>
              </button>
            :
              <button className="header-button" onClick={() => setHidden(true)}>
                <img className="header-button-img" src="/ARCH_icon_hide_white.svg"/>
                <div className="header-button-text bottom-bar">hide</div>
              </button>
          :null}
        </div>
        :null}

        {modalOpen?
          <div className={saveOpen? "modal-window gallery-item-modal extended" : "modal-window gallery-item-modal"}>
            <div>
              <div className="modal-fixed-inner">
                <a onClick={() => this.onToggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
                <img className="modal-gallery-img" src={bigImgSrc} />
                <div className="modal-gallery-bottom">
                  <div className="left">
                    <div className="caps-text-10 grey">{image.archiveNumber}</div>
                    <div className="modal-gallery-title">{image.title}</div>
                  </div>
                  {uneditable?
                    null
                  :
                    <div className="right">
                      {removeOnly?
                        <button onClick={() => this.onRemove(image.uid, lightbox) } className="modal-gallery-save">
                          <img className="modal-gallery-save-img" src="/ARCH_icon_delete.svg" />DELETE
                        </button>
                      :
                        <button onClick={() => this.onToggleSave() } className={saveOpen? "modal-gallery-save open" : "modal-gallery-save"}>
                          <img className="modal-gallery-save-img" src="/ARCH_icon_save.svg" />SAVE
                        </button>
                      }
                    </div>
                  }
                </div>
              </div>
              {saveOpen?
                  <div className="modal-extension">
                    <div>
                      <button onClick={() => this.onToggleSave() } className="mobile-show caps-text-13 red extended-back-button">
                          {'<'} BACK
                        </button>
                    </div>
                    {this.props.authUser && this.props.authUser.uid?
                      <form onSubmit={() => this.onCreateLightbox(image.uid)} className="create-lightbox-flex gallery-mod">
                        <input placeholder="Name new lightbox" className="modal-input" onChange={(e) => this.onChangeName(e)}/>
                        <button type="submit" className="create-lightbox-button"><img className="create-lightbox-go" src="/ARCH_icon_go_arrow.svg" /></button>
                      </form>
                    :null}
                    {Object.keys(lightboxes).length > 0 && this.props.authUser?
                      <div className="lightbox-list-parent">
                        {Object.values(lightboxes).map((lightbox, i) => (
                          lightbox.images.find(i => i.id === image.uid) ?
                            <button className="lightbox-list-item" key={i} onClick={() => this.onRemove(image.uid, lightbox.uid)}><img className="gallery-status-marker" src="/ARCH_icon_delete_red.svg"/>{lightbox.name}</button>
                          :
                            <button className="lightbox-list-item" key={i} onClick={() => this.onAdd(image.uid, lightbox.uid)}><img className="gallery-status-marker" src="/ARCH_icon_save.svg"/>{lightbox.name}</button>
                        ))}
                      </div>
                    :
                      <div className="modal-status">{this.props.authUser && this.props.authUser.uid? "You don't have any custom lightboxes yet." : "Sign in to create custom lightboxes."}</div>
                    }

                    {/* {Object.keys(lightboxes).length > 1?
                      <select value={lightbox} onChange={(e) => this.onChangeLightbox(e)}>
                        {Object.values(lightboxes).map((lightbox, i) => (
                          <option key={i} value={lightbox.uid}>{lightbox.name}</option>
                        ))}
                      </select>
                    :
                      Object.keys(lightboxes).length > 0 ?
                        <span>{Object.values(lightboxes)[0].name} </span>
                      : <span>{this.props.authUser && this.props.authUser.uid? "You don't have any custom lightboxes yet." : "Sign in to create custom lightboxes."}</span>
                    }
                    {Object.keys(lightboxes).length > 0 ? lightboxes[lightbox] && lightboxes[lightbox].images.find(i => i.id === image.uid) ?
                      <button onClick={() => this.onRemove(image.uid, lightbox)}>Remove</button>
                    :
                      <button onClick={() => this.onAdd(image.uid, lightbox)}>Add</button>
                    :null} */}
                  </div>
                :
                  null
                }
            </div>
          </div>
        :null}
      </span>
    );
  }
}

export default withFirebase(GalleryItem);
