export const HOST = 'https://rodneysmith.com'; // PROD
// export const HOST = 'https://staging9.rodneysmith.com'; // DEV

export const PAGE = '/archive'; // PROD
// export const PAGE = '/embed-test'; // DEV

export const HOME = '/';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const LIGHTBOXES = '/lightboxes';
export const LIGHTBOX = '/lightbox';
export const SEARCH = '/search';
export const UPLOAD = '/upload';
export const DOWNLOAD = '/download';
