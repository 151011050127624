import React from 'react';

import LightboxItem from './LightboxItem';

const LightboxList = ({
  authUser,
  lightboxes,
  onEditLightbox,
  onRemoveLightbox,
}) => (
  <div className="lightboxes-parent">
    {lightboxes.map(lightbox => (
      <LightboxItem
        authUser={authUser}
        key={lightbox.uid}
        lightbox={lightbox}
        onEditLightbox={onEditLightbox}
        onRemoveLightbox={onRemoveLightbox}
      />
    ))}
  </div>
);

export default LightboxList;
