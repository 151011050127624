import React, { Component } from 'react';

import { AuthUserContext } from './Session';
import history from '../history'
import { withFirebase } from './Firebase';

class DuplicateLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      name: ''
    };
  }

  onToggleModal = () => {
    this.setState(state => ({
      modalOpen: !state.modalOpen,
    }));
  };

  onChangeName = event => {
    this.setState({ name: event.target.value });
  };

  onDuplicateLightbox = authUser => {
    const { name } = this.state
    const { lightbox } = this.props

    let images = []

    if (lightbox.images && lightbox.images.length > 0 && lightbox.images[0].archiveNumber) {
      for (let image in lightbox.images) {
        images.push(this.props.firebase.image(lightbox.images[image].uid))
      }
    } else {
      images = lightbox.images
    }

    let payload = {
      createdAt: this.props.firebase.NOW(),
      modifiedAt: this.props.firebase.NOW(),
      creator: this.props.firebase.user(authUser.uid),
      creatorID: authUser.uid,
      images: images,
      name
    }

    if (lightbox.hidden) {
      payload["hidden"] = lightbox.hidden
    }

    this.props.firebase.lightboxes().add(payload)
    .then(function (docRef) {
      console.log("Lightbox created with ID: ", docRef.id);
      history.push('/lightboxes');
    })
    .catch(function (error) {
      console.error("Error creating lightbox: ", error);
    });
  }

  render() {
    const { invert } = this.props;
    const { modalOpen } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <span>
            <button className={invert? "header-button" : "header-button top"} onClick={() => this.onToggleModal()}>
              <img className="header-button-img" src={invert? "/ARCH_icon_duplicate_white.svg" : "/ARCH_icon_duplicate.svg"}/>
              <div className={invert? "header-button-text bottom-bar" : "header-button-text"}>duplicate</div>
            </button>

            {modalOpen?
              <div className="modal-window">
                <div>
                  <a onClick={() => this.onToggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
                  <div className="caps-text-13 red">Duplicate Lightbox</div>
                  <div className="create-lightbox-flex">
                    <input placeholder="Name duplicated lightbox" className="modal-input" onChange={(e) => this.onChangeName(e)}/>
                    <button className="create-lightbox-button" onClick={() => {this.setState({modalOpen: false}); this.onDuplicateLightbox(authUser)}}><img className="create-lightbox-go" src="/ARCH_icon_go_arrow.svg" /></button>
                  </div>
                </div>
              </div>
            :null}
          </span>
      )}
    </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(DuplicateLightbox);
