import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom'
import fileDownload from 'js-file-download';
import { withFirebase } from '../Firebase';
import DisplayFiles from '../DisplayFiles';

import * as ROUTES from '../../constants/routes';

const typeConversions = {
  'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'mpeg': 'mp3',
}

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      modalOpen: false,
      files: [],
      displayFiles: [],
      error: null,
      link: '',
      existingFolders: [],
    }
  }

  onChangeName = event => {
    let val = event.target.value.replace(/[^0-9a-zA-Z ,:/'"<>_\-!@#$%^&*()+=?;]/g, '');
    this.setState({ name: val });
  };

  onAddFiles = files => {
    let displayFiles = []
    for (let file in files) {
      let typeBlocks = files[file].type.split("/")
      let type =  typeBlocks[typeBlocks.length - 1]
      if (type in typeConversions) {
        type = typeConversions[type]
      }
      displayFiles.push({
        url: URL.createObjectURL(files[file]),
        type: type,
        name: files[file].name
      })
    }
    this.setState({ files: this.state.files.concat(files), displayFiles: this.state.displayFiles.concat(displayFiles) });
  };

  onError = e => {
    console.log(e)
  }

  promoteSelfToAdmin() {
    this.props.firebase.user(this.props.authUser.uid).update({
      admin: true
    })
  }

  onToggleModal = () => {
    this.setState(state => ({
      modalOpen: !state.modalOpen,
    }));
  };

  saveAndShare() {
    if (this.state.name.length > 0 && this.state.files.length > 0) {
      this.setState({error: null})
      let folderName = this.uuidv4()
      let promises = []
      promises.push(this.props.firebase.uploads(folderName + '/.foldername').putString(this.state.name))
      for (let file in this.state.files) {
        let fileURL = folderName + '/' + this.state.files[file].name
        let metadata = {
          customMetadata: {
            'renderType': this.state.displayFiles[file].type
          }
        }
        promises.push(this.props.firebase.uploads(fileURL).put(this.state.files[file], metadata))
      }
      Promise.all(promises)
        .then((response) => {
            this.setState({
              modalOpen: true,
              link: window.location.ancestorOrigins[0] + ROUTES.PAGE + '/?d=' + folderName
            })
        })
        .catch(err => console.log(err))
    } else {
      this.setState({error: "Please name your folder and add at least one file before sharing."})
    }
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  onCopyShareLink = () => {
    var element = document.getElementById("copy-target");
    var range, selection;

    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();        
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    
    try {
      document.execCommand('copy');
      this.setState({status: "Share link copied."})
    }
    catch (err) {
      this.setState({status: 'Could not copy share link. You can copy it manually here: ' + this.state.link});
    }
  };

  downloadFile(i) {
    if (-1 < i && i < this.props.files.length) {
      fileDownload(this.props.files[i], this.props.files[i].name);
    }
  }

  componentDidMount() {
    this.props.firebase.uploads('/')
      .listAll().then((res) => {
        let folders = []
        res.prefixes.forEach(function(prefix) {
          folders.push(prefix.location.path.split('/')[1])
        })
        this.setState({existingFolders: folders})
      }).catch(err => {
        console.log(err)
      });
  }

  render() {
    const { files, displayFiles, modalOpen, error, link, existingFolders } = this.state;

    return(
      <div>
        <div className="header-row">
          <div className="half-col">
            <div className="caps-text-13 red">Upload</div>
          </div>
          <div className="half-col right">
            <button className="header-button top" onClick={() => this.saveAndShare()}>
              <img className="header-button-img" style={{height: '28px'}} src="/ARCH_icon_share.svg"/>
              <div className="header-button-text">save and share</div>
            </button>
          </div>
        </div>
        {error? <div className="file-error">{error}</div> : null}
        <div>
          <input placeholder="Name new folder" className="modal-input" onChange={(e) => this.onChangeName(e)}/>
        </div>
        <Dropzone onDrop={acceptedFiles => this.onAddFiles(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section className="upload-dropzone-parent">
              <DisplayFiles displayFiles={displayFiles} downloadFile={(i) => this.downloadFile(i)} />
              <div {...getRootProps()} className="upload-dropzone">
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone>
        {modalOpen?
          <div className="modal-window">
            <div>
              <a onClick={() => this.onToggleModal()} title="Close" className="modal-close"><img className="modal-close-img" src="/ARCH_icon_delete.svg"/></a>
              <div className="caps-text-13 red">Share Files</div>
              {this.state.status? <div className="modal-status">{this.state.status}</div> :null}
              <div>
                <div id="copy-target">{link}</div>
                <button className="modal-button dark-gray" onClick={() => this.onCopyShareLink()}>COPY A LINK</button>
                <a className="modal-button modal-link dark-gray" href={"mailto:?subject=Your%20Rodney%20Smith%20files&body=View%20your%20files%20at%20" + encodeURIComponent(this.state.link)}>EMAIL A LINK</a>
                <a className="modal-button modal-link dark-gray" target="_blank" href={this.state.link}>VIEW DOWNLOAD PAGE</a>
              </div>
            </div>
          </div>
        :null}
        <br/>
        <br/>
        <div>
          <div className="caps-text-13">Existing Folders ({existingFolders.length} total)</div>
          {existingFolders.map(folder => (
            <div>
              <br/>
              <a href={`${ROUTES.DOWNLOAD}/?d=${folder}`}>
                {folder}
              </a>
            </div>
          ))}
        </div>
        <br/>
      </div>
    )
  }
}

export default withFirebase(Upload);
